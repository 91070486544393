import React, {
  ButtonHTMLAttributes,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { asEnumerable } from 'linq-es2015';
import { useToast } from '../../hooks/Toast';
import { useEvent } from '../../hooks/EventContext';
import moment from 'moment';
import ContainerButton from '../ContainerButton';

import { useTranslation } from 'react-i18next';
import { useSpeaker } from '../../hooks/SpeakersContext';

import { useParams } from 'react-router-dom';
import { useTheme } from '../../hooks/ThemeContext';
interface Props {
  user: any;
  channelId: string;
  style?: any;
  customization?: any;
  eventId: string;
}

interface IQuestion {
  id: string;
  channelId: string;
  eventId: string;
  speakerId: string;
  question: string;
  speaker: string;
  response: string;
  userId: string;
  name: string;
  email: string;
  createdAt: number;
  status: string;
}

interface IQuestionData {
  question: string;
  speaker: string;
  speakerId: string;
  status: string;
}

interface ITest {
  id: string;
}

const QuestionToSpeaker: React.FC<Props> = (props) => {
  const { event, channel: channelKey } = useParams<{
    channel: string;
    event: string;
  }>();
  const { theme } = useTheme();
  const { addToast } = useToast();
  const { register, unregister, setValue, handleSubmit, errors, getValues } =
    useForm<IQuestionData>();
  const firestore = firebase.firestore();
  const eventContext = useEvent();
  const [speakers, setSpeakers] = useState<any[]>([]);
  // const { speakers } = useSpeaker();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (eventContext.channel.eventId) {
      const unsubscribe = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', eventContext.channel.eventId)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          const spe = docs.sort(function (a: any, b: any) {
            return a.order - b.order;
          });
          console.log('spe:', spe);
          setSpeakers(spe);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [eventContext.channel.eventId]);

  async function sendMessage(
    question: string,
    speaker: string,
    speakerId: any = null,
  ) {
    setLoading(true);
    const data = {
      channelId: props.channelId,
      eventId: props.eventId,
      question,
      speaker,
      speakerId,
      userId: props.user.id,
      email: props.user.email,
      name: props.user.name || props.user.email,
      createdAt: moment.utc().valueOf(),
      status: 'received',
    } as IQuestion;

    const ref = await firestore.collection('question').add(data);

    addToast({
      type: 'success',
      title: t('Data sent successfully'),
      description: t('Thank you for your participation'),
    });

    setLoading(false);
  }

  async function handleSendMessage(data: any) {
    try {
      const schema = Yup.object().shape({
        question: Yup.string().required(t('Please fill in the field')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const speakersObj: any = {};
      speakers.forEach((v: any) => {
        speakersObj[v.id] = v;
      });

      console.log(data);
      await sendMessage(
        data.question,
        speakersObj[data.speaker].name,
        data?.speaker || '',
      );

      setValue('question', '');
    } catch (error) {
      addToast({
        type: 'error',
        title: t('Failed to send response'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('An error occurred while registering, please try again'),
      });
    }
  }

  return (
    <Conteiner
      id="survey"
      theme={theme}
      customization={eventContext?.event?.customization || {}}
    >
      {/* <p className="bp-title mb-4">{t('Ask the speaker')}</p> */}
      <fieldset className="question-speaker-fieldset form-group message-list">
        <div className="question-speaker-header">
          <h3>{t('Send a question to speakers')}</h3>
        </div>
        <form
          onSubmit={handleSubmit(handleSendMessage)}
          className="question-speaker-form"
        >
          <div className="question-speaker-form-inputs">
            {/* <label
              htmlFor="dropdown-basic-button"
              className="question-speaker-form-label question-speaker-form-label-select"
            > */}
            <select
              name="speaker"
              id="dropdown-basic-button"
              className="question-speaker-form-select"
              // className="question-speaker-form-input"
              title=""
              ref={register({ required: true })}
            >
              {speakers
                // {eventContext.channel.speakers
                // ?.filter(
                //   (speaker: any) =>
                //     speaker?.views?.findIndex(
                //       (v: any) =>
                //         v.key === 'ask' && v.channelId === props.channelId,
                //     ) >= 0,
                // )
                .map((item: any, i: number) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            {/* </label> */}
            <label htmlFor="question" className="question-speaker-form-label">
              <textarea
                className="question-speaker-form-input"
                name="question"
                id="question"
                ref={register({ required: true, maxLength: 1000 })}
                cols={30}
                rows={4}
              ></textarea>
            </label>
          </div>

          {errors.question && (
            <span className="error" style={{ float: 'left' }}>
              {t('Please fill in the question')}
            </span>
          )}

          <ContainerButton loading={loading} classe="question-speaker-btn-send">
            <p>{t('Send')} </p>
            <i className="fas fa-paper-plane"></i>{' '}
          </ContainerButton>
        </form>
      </fieldset>
    </Conteiner>
    // <Conteiner customization={props.customization} style={props?.style} className="chatbox">
    //    <p className="bp-title mb-4">Pergunte ao Palestrante</p>
    //   <form onSubmit={handleSubmit(handleSendMessage)}>
    //     <select name="speaker" id="dropdown-basic-button" className="form-control form-text"  title="Escolha o palestrante" ref={register({ required: true })}>
    //       {speakers.map((item: any, i: number) =>
    //         (<option key={i} value={item.id}>{item.name}</option>)
    //       )}
    //     </select>
    //     <textarea className="form-control form-text" name="question" ref={register({ required: true, maxLength: 1000 })} cols={30} rows={4}></textarea>

    //     <ContainerButton loading={loading} classe="buttonSendMessage">
    //       Enviar <i className="fa fa-paper-plane" style={{color:'#fff'}}></i>
    //     </ContainerButton>

    //     <React.Fragment>{errors.question && <span className="error" style={{ float: 'right' }}>Por favor preencha a pergunta</span>}</React.Fragment>

    //   </form>
    // </Conteiner>
  );
};

export default QuestionToSpeaker;
