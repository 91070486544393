import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import './style.css';

import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
interface IMyProps {
  theaterMode: boolean;
  eventTitle?: string;
  eventRoom?: string;
  eventDate?: string;
}

const HeaderTitle: React.FC<IMyProps> = ({
  theaterMode,
  eventDate,
  eventRoom,
  eventTitle,
}) => {
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { t, i18n } = useTranslation();
  const { event, channel, ...eventContext } = useEvent();
  const { theme } = useTheme();

  useEffect(() => {
    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);

  return (
    <S.Container
      theaterMode={theaterMode}
      theme={theme}
      customization={event?.customization}
    >
      <S.EventTitle style={{ color: event?.customization?.fontColor }}>
        {eventTitle || event?.name || 'Titulo do evento'}
      </S.EventTitle>
      <S.EventInfo>
        {eventRoom ||
        channel?.name_languages?.[i18n.language] ||
        channel?.name ? (
          <S.EventRoom style={{ color: event?.customization?.fontColor }}>
            {' '}
            Sala:{' '}
            {eventRoom ||
              channel?.name_languages?.[i18n.language] ||
              channel?.name}
          </S.EventRoom>
        ) : (
          ''
        )}{' '}
        {eventDate || event?.eventdate ? (
          <S.EventDate
            dateBgColor={event?.customization?.dateBgColor}
            hourBgColor={event?.customization?.hourBgColor}
          >
            {channel?.channel_date
              ? `${channel?.channel_date}`
              : `${event?.eventdate}`}{' '}
            -{' '}
            <span>
              {channel?.hour ? `${channel?.hour}` : `${event?.eventhour}`}
            </span>{' '}
          </S.EventDate>
        ) : (
          ''
        )}
      </S.EventInfo>
    </S.Container>
  );
};
export default HeaderTitle;
