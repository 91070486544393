import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useEvent } from './EventContext';

const SpeakersContext = createContext<SpeakerData>({} as SpeakerData);

export interface SpeakerData {
  speakers: any[];
}

const SpeakersProvider: React.FC = ({ children }) => {
  const { event } = useEvent();
  const [eventId, setEventId] = useState<string>();
  const [speakers, setSpeakers] = useState<any>(null);

  // async function loadSpeakers($eventId: string): Promise<void> {
  //   try {
  //     if (eventId === $eventId) return;

  //     console.log('***** SpeakersContext loadSpeakers *****');

  //     setEventId($eventId);

  //     // const result = await api.get(`/chat?channelId=${$channelId}`);
  //     const $user = firebase.auth()?.currentUser;
  //     const firestore = firebase.firestore();
  //     const docSnap = await firestore
  //       .collection('speaker')
  //       .where('eventId', '==', $eventId)
  //       .get();

  //     const $speakers = docSnap?.docs?.map((doc) => doc?.data());

  //     console.log('loadSpeakers', { $speakers, docSnap });

  //     setSpeakers($speakers);
  //   } catch (error) {
  //     console.log('loadSpeakers error', { error, channelId: eventId });
  //   }
  // }

  // useEffect(() => {
  //   console.log('speakersContext useEffect');
  //   if (event?.id && event.id !== eventId) {
  //     loadSpeakers(event.id);
  //   }
  // }, [event?.id]);

  useEffect(() => {
    if (event?.id) {
      const unsubscrible = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', event?.id)
        .onSnapshot((snapshot) => {
          console.log('speakers changed');

          const data = snapshot?.docs?.map((doc) => doc?.data());
          const sortedDataByOrderNumber = data.sort(
            (a: any, b: any) => Number(a.order) - Number(b.order),
          );
          setSpeakers(sortedDataByOrderNumber);
        });

      return () => {
        unsubscrible();
      };
    }
  }, [event]);

  // console.log('render speakers context', event);

  return (
    <>
      <SpeakersContext.Provider
        value={{
          speakers,
        }}
      >
        {children}
      </SpeakersContext.Provider>
    </>
  );
};

function useSpeaker(): SpeakerData {
  const context = useContext(SpeakersContext);

  if (!context) {
    throw new Error('useSpeaker must be used within an SpeakerProvider');
  }

  return context;
}

export { SpeakersProvider, useSpeaker };
