import styled, { css } from 'styled-components';

interface ContainerPros {
  customization: any;
  theme: any;
}

export const Conteiner = styled.div<ContainerPros>`
  height: 100%;
  background-color: ${(props) => props.theme.colors.grayColor};
  /* padding-bottom: 90px; */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(180, 180, 180);
  }
  .question-speaker-fieldset {
    padding: 0 1rem;
  }
  .bp-title {
    margin-left: 10px;
  }
  /* position:relative;
  height: 545px;
  padding: 10px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  color: #000000;
  padding: 17px;
  opacity: 1;

  .buttonSendMessage{
    position:absolute;
    bottom:10px;
  }
  p {
    font-size: 13px;
  }
  select#dropdown-basic-button {
      padding: 5px;
  }
  .btn-primary {
    background-color: #191919;
    border-color: #191919;
}
  span {
    font-size: 18px;
    position: relative;
    top: 8px !important;
  }
  .action-button {
    margin-top: 6px !important;
    float: right;
    padding: 4px 30px;
  }
  textarea {
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    border: none;
    height: 120px;
    color: #000;
    border: 1px solid #DDD;
  } */
  .question-speaker-header {
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #c5c4c4;
    margin-bottom: 32px;
    h3 {
      padding: 1rem 0;
      font-size: 0.88rem !important;
      margin: 0;
      font-weight: 400;
      color: ${(props) => props.theme.colors.askText};
    }
  }
  .question-speaker-form {
    display: flex;
    flex-direction: column;
    .question-speaker-form-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .question-speaker-form-label {
        width: 100%;
        min-height: 45px;
        border: 1px solid #808080;
        text-align: center;
        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        * {
          width: 100%;
          height: inherit;
          background: transparent;
          border: 0;
          outline: none;
          color: #808080;
          padding: 0.5rem;
        }
        select {
          position: relative;
        }
        textarea {
          padding: 1rem;
          min-height: 120px;
          max-height: 200px;
        }

        &.question-speaker-form-label-select {
          border-radius: 25px;
          padding: 0 1.5rem;
          &::before {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .question-speaker-btn-send {
      width: 100%;
      border-radius: 24px;
      padding: 0 1.5rem;
      margin-top: 24px;
      height: 44px;
      border: 0;
      /* background: linear-gradient(90deg, #12C3FF 20%, #4269FF  100%); */
      background: ${(props) => props.customization.buttonBackgroundColor};
      background-repeat: no-repeat;
      background-size: 150%;
      background-position-x: -0%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in;
      p {
        flex: 1;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.88rem;
        color: ${(props) => props.customization.buttonTextColor};
      }
      &:hover {
        transition: all 0.2s ease-out;
        background-position-x: 100%;
      }
    }
  }

  .question-speaker-form-select {
    width: 100%;
    min-height: 45px;
    border: 1px solid #808080;
    text-align: center;
    border-radius: 10px;
    outline: none;
  }
`;
