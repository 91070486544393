import React, { useRef, useEffect, useState } from 'react';
import { useEvent } from '../../hooks/EventContext';
// import './SpeakersContainer.css';

import { useSpeaker } from '../../hooks/SpeakersContext';
import { useTheme } from '../../hooks/ThemeContext';
import ModalSpeakers from '../ModalSpeakers';
import { useTranslation } from 'react-i18next';
import HeaderPageDescription from '../HeaderPageDescription';
import { Icon } from '../Icons';
import * as S from './style';

interface Speak {
  id: string;
  name: string;
  picture: string;
  profession: any;
  linkedin: string;
  instagram: string;
  twitter: string;
  facebook: string;
}
const SpeakersContainer: React.FC = (props: any) => {
  const eventContext = useEvent();
  const { speakers } = useSpeaker();
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  const [isModalVisible, seIsModalVisible] = useState(null);

  function handleOutsideClickOpen(id: any) {
    seIsModalVisible(id);
  }

  function handleOutsideClickOnClose(id: any) {
    seIsModalVisible(null);
  }

  return (
    <S.Container>
      <HeaderPageDescription
        title={`${t('Speakers')}`}
        // description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`}
      />

      <S.ContainerCards>
        {speakers?.map((speaker: Speak) => (
          <div>
            <S.Card key={`card-speaker-item-${speaker.id}`} theme={theme}>
              <S.CardContent>
                <S.CardImageContainer>
                  <S.CardImage
                    src={speaker?.picture}
                    alt={`Speaker${speaker.id}`}
                  />
                </S.CardImageContainer>
                <S.CardInfo>
                  <S.CardInfoHeader>
                    <S.CardTitle theme={theme}>{speaker?.name}</S.CardTitle>
                    <S.CardProfession>
                      {speaker?.profession?.[i18n.language] || ''}
                    </S.CardProfession>
                  </S.CardInfoHeader>
                  <S.CardButtonAccess
                    customization={eventContext?.event?.customization || {}}
                    onClick={() => handleOutsideClickOpen(speaker.id)}
                  >
                    {t('Profile Access')}
                  </S.CardButtonAccess>
                </S.CardInfo>
              </S.CardContent>
              {speaker?.linkedin ||
              speaker?.instagram ||
              speaker?.twitter ||
              speaker?.facebook ? (
                <S.CardFooter>
                  {speaker?.linkedin && (
                    <S.LinkFooter href={speaker?.linkedin} target="_blank">
                      <Icon name="linkedin" />
                    </S.LinkFooter>
                  )}
                  {speaker?.instagram && (
                    <S.LinkFooter target="_blank" href={speaker?.instagram}>
                      <Icon name="instagram" />
                    </S.LinkFooter>
                  )}
                  {speaker?.twitter && (
                    <S.LinkFooter target="_blank" href={speaker?.twitter}>
                      <Icon name="twitter" />
                    </S.LinkFooter>
                  )}
                  {speaker?.facebook && (
                    <S.LinkFooter target="_blank" href={speaker?.facebook}>
                      <Icon name="facebook" />
                    </S.LinkFooter>
                  )}
                </S.CardFooter>
              ) : (
                ''
              )}
            </S.Card>
            {isModalVisible === speaker.id ? (
              <ModalSpeakers
                onClose={handleOutsideClickOnClose}
                key={`modal-speaker-${speaker.id}`}
                speaker={speaker}
              />
            ) : null}
          </div>
        ))}
      </S.ContainerCards>
    </S.Container>
  );
};
export default SpeakersContainer;
