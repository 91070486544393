import React, { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import { useEvent } from '../../hooks/EventContext';

import './ScheduleContainer.css';
import { useSpeaker } from '../../hooks/SpeakersContext';
import { asEnumerable } from 'linq-es2015';

import * as S from './style';
import { Icon } from '../Icons';
import api from '../../services/api';
import HeaderPageDescription from '../HeaderPageDescription';
import { useTheme } from '../../hooks/ThemeContext';
interface Speakerinfor {
  hour?: string;
  name?: string;
  picture?: string;
  profession?: {
    pt: string;
    en: string;
    es: string;
  };
  description?: {
    pt: string;
    en: string;
    es: string;
  };
}
interface InfoSchedule {
  id?: string;
  day?: string;
  date?: string;
  speakers?: Speakerinfor[];
  description?: {
    pt: string;
    en: string;
    es: string;
  };
  start: number;
}

interface Agenda {
  id: string;
  day: string;
  date: string;
  hour: string;
  profission: string;
  name: string;
  descriptionH3: string;
  descriptionP: string;
  orderNumber: number;
  eventKey: string;
  eventId: string;
  speaker: Speak;
}

interface Speak {
  id: string;
  name: string;
  picture: string;
  description: any;
  profession: any;
}

const ScheduleContainer: React.FC = () => {
  const { speakers: $speakers } = useSpeaker();
  const [speakers, setSpeakers] = useState<any>({});
  const firestore = firebase.firestore();
  const [schedule, setSchedule] = useState<any>({
    programation: [],
    programation_language: [],
  });
  const { channel, event, ...eventContext } = useEvent();
  const [datas, setDatas] = useState<Array<InfoSchedule>>([]);
  const [visibleTab, setVisibleTab] = useState<string>('1');
  const [agenda, setAgenda] = useState<Agenda[]>([]);
  const [agendaFiltered, setAgendaFiltered] = useState<Agenda[]>([]);
  // const [orders, setOrders] = useState<any[]>([]);
  const [dates, setDates] = useState<any[]>([]);
  const [days, setDays] = useState<any[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  useEffect(() => {
    const speakerObj: any = {};

    if (!$speakers?.length) return;

    $speakers.map((speaker: any) => {
      speakerObj[speaker.id] = speaker;
      return speaker;
    });
    setSpeakers(speakerObj);
  }, [$speakers]);

  useEffect(() => {
    if (channel?.id) {
      const unsubscribe = firestore
        .collection('schedule')
        .doc(channel.id)
        .onSnapshot((snapshot) => {
          const schedule: any = snapshot.data() || {};
          schedule.programation_language = asEnumerable(
            schedule.programation_language || [],
          )
            .Where((u: any) => u.status == 'actived' || u.status == 'enabled')
            .ToArray();
          const programation: any = asEnumerable(
            schedule.programation_language,
          ).FirstOrDefault((a: any) => a.status == 'actived');
          setSchedule(schedule);

          eventContext.setProgramation(programation);
          setDatas(schedule?.programation_language);
          if (programation?.speakers?.length) {
            eventContext.setSpeakers(
              programation?.speakers || eventContext.speakers,
            );
          } else {
            let speakers: any[] = [];
            asEnumerable(schedule.programation_language)
              .Select((item: any) => {
                speakers = asEnumerable(speakers)
                  .Union(asEnumerable(item.speakers || []))
                  .Select((speaker: any) => {
                    return {
                      id: speaker.id,
                      name: speaker.name,
                    } as any;
                  })
                  .Distinct()
                  .ToArray();
              })
              .ToArray();
            eventContext.setSpeakers(
              speakers.length ? speakers : eventContext.speakers,
            );
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [channel?.id]);

  function initAgenda(agendas: Agenda[]) {
    const orders: string[] = Array.from(
      new Set(agendas.map((value: any) => value.day)),
    );

    console.log('agenda useEffect', { orders, agendas });

    setAgenda(agendas);
    // setOrders(orders);
    setVisibleTab(orders[0]);
    setIsBusy(false);
  }

  useEffect(() => {
    api
      .get(`/agenda/${event.id}`)
      .then((r) => initAgenda(r.data))
      .catch((e) => console.log('error agenda api', e));
    const unsubscribe = firestore
      .collection('agenda')
      .where('eventId', '==', event.id)
      .orderBy('orderNumber', 'asc')
      .onSnapshot(
        (snapshot) => {
          const agendas: any = snapshot.docs.map((d) => ({
            ...d.data(),
            id: d.id,
          }));

          initAgenda(agendas);
        },
        (error) => {
          console.log('error agenda snapshot', error);
        },
      );

    return () => {
      unsubscribe?.();
    };
  }, [event]);
  // const loadAgenda = () => {
  //   if (!event.id) return;
  //   api.get(`/agenda/${event.id}`).then((r) => {
  //     let daysArray = Array.from(new Set(r.data.map((value:any) => value.day))).map((value) => {
  //       return {
  //         day: value
  //       }
  //     })
  //     let ordersArray = Array.from(new Set(r.data.map((value: any) => value.orderNumber))).map((value) => {
  //       return {
  //         order: value
  //       }
  //     })
  //     let datesArray = Array.from(new Set(r.data.map((value: any) => value.date))).map((value) => {
  //       return {
  //         date: value
  //       }
  //     })

  //     setDates(datesArray)
  //     setOrders(ordersArray)
  //     setDays(daysArray)
  //     // setVisibleTab(Number(daysArray[0]?.day))
  //     setVisibleTab(Number(ordersArray[0]?.order))
  //     setAgenda(r.data);

  //   }).finally(() => setIsBusy(false))
  // }

  // useEffect(() => {
  //   if (event?.id) {
  //     loadAgenda()
  //   }
  // }, [event?.id])

  // useEffect(() => {
  //   if (!visibleTab && isBusy) return;
  //   setAgendaFiltered(() => {
  //     // return agenda.filter(item => +item.day === visibleTab)
  //     return agenda.filter((item: any) => +item.orderNumber === visibleTab);
  //   });
  // }, [visibleTab, isBusy]);

  const filtered_schedule = React.useMemo(() => {
    return agenda
      .filter((item) => item.day === visibleTab)
      .sort((a, b) => a.orderNumber - b.orderNumber); // Compare order numbers
    //sort ascending hour
    // .sort((a, b) => {
    //   const timeA = a.hour.split(':').map(Number);
    //   const timeB = b.hour.split(':').map(Number);
    //   if (timeA[0] !== timeB[0]) {
    //     return timeA[0] - timeB[0]; // Compare hours
    //   } else {
    //     return timeA[1] - timeB[1]; // Compare minutes if hours are equal
    //   }
    // });
  }, [agenda, visibleTab]);

  const orders = React.useMemo(() => {
    return Array.from(new Set(agenda.map((value: any) => value.day)));
  }, [agenda]);

  const schedule_obj = React.useMemo(() => {
    const obj: any = {};
    orders.forEach((order) => {
      obj[order] = agenda.find((item) => item.day === order);
    });

    return obj;
  }, [agenda, orders]);

  return (
    <S.Container>
      <HeaderPageDescription title={`${t('Schedule')}`}>
        <S.ContainerButtonSetDate>
          {orders.map((order) => {
            return (
              <S.ButtonSetDateShedule
                key={`tab_schedule_${order}`}
                type="button"
                onClick={() => setVisibleTab(order)}
                isActive={visibleTab === order}
                customization={event?.customization || {}}
              >
                {schedule_obj?.[order]?.date &&
                  `${schedule_obj?.[order]?.day} ${
                    schedule_obj?.[order]?.date?.trim() ? '-' : ''
                  } ${schedule_obj?.[order]?.date}`}
              </S.ButtonSetDateShedule>
            );
          })}
        </S.ContainerButtonSetDate>
      </HeaderPageDescription>
      <S.ContainerSchedule>
        <S.CardScheduleGroup>
          {!isBusy &&
            filtered_schedule.map((item: Agenda, index: number) => (
              <S.CardSchedule
                key={`card-schedule-item-speaker-${
                  item.id + ' ' + item?.name + ' ' + item?.speaker?.name
                }`}
                delay={index * 0.025}
                theme={theme}
              >
                <S.CardDate theme={theme}>
                  <Icon name="clock" />
                  <span className="shedule-card-item-hour">
                    {item.hour || '00h00'}{' '}
                  </span>
                </S.CardDate>
                <S.CardSpeakerImage>
                  {item?.speaker?.picture && (
                    <img
                      src={item?.speaker?.picture}
                      alt={item.speaker?.name || ''}
                    />
                  )}
                </S.CardSpeakerImage>
                <S.CardContent>
                  <S.CardHeader>
                    <S.CardTitle theme={theme}>
                      {item.speaker?.name}
                    </S.CardTitle>
                    {item.speaker?.profession?.[i18n.language] && (
                      <S.CardProfission>
                        {item.speaker?.profession?.[i18n.language] || ''}
                      </S.CardProfission>
                    )}
                  </S.CardHeader>
                  <b>
                    {item?.descriptionH3 && (
                      <S.CardText
                        theme={theme}
                        dangerouslySetInnerHTML={{
                          __html: item?.descriptionH3 || '',
                        }}
                      />
                    )}
                  </b>
                  {item?.descriptionP && (
                    <S.CardText
                      theme={theme}
                      dangerouslySetInnerHTML={{
                        __html: item?.descriptionP || '',
                      }}
                    />
                  )}
                </S.CardContent>
              </S.CardSchedule>
            ))}
        </S.CardScheduleGroup>
      </S.ContainerSchedule>
    </S.Container>
  );
};

export default ScheduleContainer;
